import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormInput } from '@app/shared/models/form-input';
import {
  validEmail,
  checkWhitespace,
  checkPasswordLength,
  checkPasswordHasLowerCase,
  checkPasswordHasUpperCase,
  checkPasswordHasSpecialCharacter,
  checkPasswordHasNumbers,
  checkFieldNoNumber,
  checkZipCodeValidity,
  checkPhoneLoginValidity,
  emailMatchValidator
} from '@app/modules/registration/customValidators';
import { HelperService } from '@app/shared/services/helper.service';
import { getErrorMessage } from '@app/shared/helpers/validator';
import { CONFIRMEMAILKEY, EMAILKEY, PASSWORDKEY } from '@app/modules/registration/registration.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vs-input',
  templateUrl: './vs-input.component.html',
  styleUrls: ['./vs-input.component.scss']
})
export class VsInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() config: FormInput;
  @Input() registrationForm: FormGroup;
  @ViewChildren('formInput') formInput: ElementRef<HTMLInputElement>;
  private valueChangesSub: Subscription = null;
  showValidationLabels = false;
  showError = true;
  passwordVisibility = false;
  formControl;
  randomPassword: string;
  isPasswordField: boolean;
  focusedInput: HTMLInputElement;
  hasLabelHidden: boolean;

  constructor(private cdRef: ChangeDetectorRef, private helperService: HelperService) {}

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.formControl = this.registrationForm.get(this.config.key) as FormControl;

    if (!this.formControl) {
      this.formControl = new FormControl('', { updateOn: 'blur' });
      this.registrationForm.addControl(this.config.key, this.formControl);
    }
    const validators = [];
    if (this.config.hasLabelHidden) {
      this.hasLabelHidden = this.config.hasLabelHidden;
    }

    if (this.config.required) {
      validators.push(Validators.compose([checkWhitespace()]));
      validators.push(Validators.required);
    }

    if (this.config.isEmail) {
      validators.push(Validators.compose([validEmail()]));
    }

    if (this.config.validators?.minLength) {
      validators.push(Validators.compose([checkPasswordLength()]));
    }

    if (this.config.validators?.hasLowerCase) {
      validators.push(Validators.compose([checkPasswordHasLowerCase()]));
    }

    if (this.config.validators?.hasNumber) {
      validators.push(Validators.compose([checkPasswordHasNumbers()]));
    }

    if (this.config.validators?.hasUpperCase) {
      validators.push(Validators.compose([checkPasswordHasUpperCase()]));
    }

    if (this.config.validators?.hasSpecialCharacter) {
      validators.push(Validators.compose([checkPasswordHasSpecialCharacter()]));
    }

    if (this.config.validators?.noNumber) {
      validators.push(Validators.compose([checkFieldNoNumber()]));
    }

    if (this.config.validators?.zipCode) {
      validators.push(Validators.compose([checkZipCodeValidity()]));
    }

    if (this.config.validators?.phoneLogin) {
      validators.push(Validators.compose([checkPhoneLoginValidity()]));
    }
    if (this.config.key === PASSWORDKEY) {
      this.isPasswordField = true;
    }
    this.formControl.setValidators(validators);
    this.formControl.updateValueAndValidity();

    if (this.config.key === EMAILKEY || this.config.key === CONFIRMEMAILKEY) {
      this.registrationForm.setValidators(emailMatchValidator('email', 'confirm_email'));
      this.registrationForm.updateValueAndValidity();
    }
  }

  onFocusInOut(action: string, event: FocusEvent): void {
    if (action === 'in') {
      if (!this.isPasswordField) {
        this.formControl.setValidators([]);
        this.registrationForm.clearValidators();
        this.formControl.updateValueAndValidity();
      } else {
        this.showError = false;
        this.showValidationLabels = true;
      }
    } else if (action === 'out') {
      const emailControl = this.registrationForm.get(EMAILKEY);
      const confirmEmailControl = this.registrationForm.get(CONFIRMEMAILKEY);

      if (emailControl && confirmEmailControl && emailControl.value !== confirmEmailControl.value) {
        this.registrationForm.setValidators(emailMatchValidator('email', 'confirmEmail'));
        this.registrationForm.updateValueAndValidity();
      } else {
        confirmEmailControl?.setErrors(null);
      }
      confirmEmailControl?.updateValueAndValidity();

      const validators = [];
      if (this.config.hasLabelHidden) {
        this.hasLabelHidden = this.config.hasLabelHidden;
      }

      if (this.config.required) {
        validators.push(Validators.compose([checkWhitespace()]));
        validators.push(Validators.required);
      }

      if (this.config.isEmail) {
        validators.push(Validators.compose([validEmail()]));
      }

      if (this.config.validators?.minLength) {
        validators.push(Validators.compose([checkPasswordLength()]));
      }

      if (this.config.validators?.hasLowerCase) {
        validators.push(Validators.compose([checkPasswordHasLowerCase()]));
      }

      if (this.config.validators?.hasNumber) {
        validators.push(Validators.compose([checkPasswordHasNumbers()]));
      }

      if (this.config.validators?.hasUpperCase) {
        validators.push(Validators.compose([checkPasswordHasUpperCase()]));
      }

      if (this.config.validators?.hasSpecialCharacter) {
        validators.push(Validators.compose([checkPasswordHasSpecialCharacter()]));
      }

      if (this.config.validators?.noNumber) {
        validators.push(Validators.compose([checkFieldNoNumber()]));
      }

      if (this.config.validators?.zipCode) {
        validators.push(Validators.compose([checkZipCodeValidity()]));
      }

      if (this.config.validators?.phoneLogin) {
        validators.push(Validators.compose([checkPhoneLoginValidity()]));
      }
      this.formControl.setValidators(validators);
      this.formControl.updateValueAndValidity();
    }
    this.helperService.showKeyboard(true);
    this.helperService.setKeyboardInputConfig(this.config);
  }

  getRandomStrongPassword(passwordEmitted: string): void {
    this.randomPassword = passwordEmitted;
  }

  setRandomGeneratedPassword(): void {
    this.formControl.setValue(this.randomPassword);
  }

  resetInput(): void {
    this.helperService.clearKeyboardInput(true, this.config.key);
    this.formControl.setValue('');
    this.formControl.updateValueAndValidity();
  }

  getInputValidationErrors(): ValidationErrors | null | Record<string, never> {
    if (this.config.key === PASSWORDKEY) {
      return this.formControl.errors ?? {};
    }
    return null;
  }

  getErrorMessage(): string {
    const currentError = Object.keys(this.formControl.errors)[0];
    if (this.config.key === EMAILKEY) {
      this.hasLabelHidden = currentError === 'emailOrNumberExists';
    }
    return getErrorMessage(currentError);
  }

  ngOnDestroy(): void {
    this.valueChangesSub?.unsubscribe();
  }
}
